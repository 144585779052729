import React from "react";

import Couple1 from "../../assets/img/couple/couple1.jpg";
import Couple2 from "../../assets/img/couple/couple2.jpg";
import Couple3 from "../../assets/img/couple/couple3.jpg";
import Couple4 from "../../assets/img/couple/couple4.jpg";
import Couple5 from "../../assets/img/couple/couple5.jpg";
import Couple6 from "../../assets/img/couple/couple6.jpg";
import Couple7 from "../../assets/img/couple/couple7.jpg";
import Carousel from "./Carousel";

const CouplePictures = [
  Couple1,
  Couple2,
  Couple3,
  Couple4,
  Couple5,
  Couple6,
  Couple7,
];

const Couple = () => {
  return (
    <div>
      <Carousel images={CouplePictures} />
    </div>
  );
};

export default Couple;
