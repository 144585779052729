import React from "react";

import Family1 from "../../assets/img/family/family1.jpg";
import Family2 from "../../assets/img/family/family2.jpg";
import Family3 from "../../assets/img/family/family3.jpg";
import Family4 from "../../assets/img/family/family4.jpg";
import Family5 from "../../assets/img/family/family5.jpg";
import Family6 from "../../assets/img/family/family6.jpg";
import Family7 from "../../assets/img/family/family7.jpg";
import Family8 from "../../assets/img/family/family8.jpg";
import Family9 from "../../assets/img/family/family9.jpg";

import Carousel from "./Carousel";

const FamilyPictures = [
  Family1,
  Family2,
  Family3,
  Family4,
  Family5,
  Family6,
  Family7,
  Family8,
  Family9,
];

const Family = () => {
  return (
    <div>
      <Carousel images={FamilyPictures} />
    </div>
  );
};

export default Family;
