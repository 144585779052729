import React from "react";

import Price1 from "../../assets/img/prix/price7.png";

const PricesCards = () => {
  return (
    <>
      <div className="pricesCards">
        <div className="pricesCards__card pricesCards--card1">
          <img src={Price1} alt="" />
        </div>
      </div>
    </>
  );
};

export default PricesCards;
